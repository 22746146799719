import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Nav from "./Header/nav";
import About from "./Pages/about";
import Home from "./Pages/home";
import Projects from "./Pages/projects";
import Footer from "../src/Footer/footer";
import Logo2 from "../src/assets/img/logo.png";
import Contact from "./Pages/contact";
import Page404 from "./Pages/404";
function App() {
  return (
    <>
      <Router>
        <div className="container-fluid">
          <div className="row  cd-nav justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div className="col-lg-4 block-padding-logo">
                  <p className="block-logo">
                    <Link to="/"><img src={Logo2} alt="Creative Digitals" /></Link>
                  </p>
                </div>
                <div className="col-lg-8 justify-content-end">
                  <Nav />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Routes>
                <Route path="/" element={<Home />} index />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </div>
          </div>
        </div>
        <footer className="container">
          <div className="row">
            <div className="col-lg-12">
              <Footer />
            </div>
          </div>
        </footer>
      </Router>
    </>
  );
}
export default App;