import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';

const Contact = () => {
  const [from_name, setFrom_name] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const form = useRef();
  const SendMessage = (e) => {
    e.preventDefault();
    if (from_name === "" || email === "" || message === "") {
      setErrorMessage(
        <div className="alert alert-danger">Please populate all fields</div>
      )
    }
    else {
      emailjs.sendForm('gmail', 'template_gs911yz', e.target, 'ACPVXqzPu-ywtKtA7')
        .then((result) => {
          setErrorMessage(
            <div className="alert alert-success">Email sent</div>
          )
        }, (error) => {
          console.log(error.text);
        });
      e.target.reset();
    }
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 block-about-us">
            <h2>Get in touch </h2>
            <p>
              We love hearing from you! Connect with our team through email, telephone, or social media and we’ll get back
              to you as soon as we can. Email us at info@creativedigitals.co.za.
            </p>
            <form className="form" ref={form} onSubmit={SendMessage}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Full name
                </label>
                <input type="text" className="form-control" id="name" placeholder="Full name" name="from_name"
                  onChange={(e) => setFrom_name(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Email address
                </label>
                <input type="email" className="form-control" id="email" placeholder="email@example.com" name="email"
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  Message
                </label>
                <textarea className="form-control" id="message" rows="3" name="message" onChange={(e) => setMessage(e.target.value)}>
                </textarea>
              </div>
              <input type="submit" className="btn btn-primary block-primary" value="Get in touch" />
              <br /><br />
              {errorMessage}
            </form>
          </div>
          <div className="col-lg-5 block-about-us">
            <h2>Contact details</h2>
            <p>
              <b>Location:</b> Gauteng, Pretoria
              <br />
              <b>Email:</b> info@creativedigitals.co.za
              <br />
              <b>Website:</b> www.creativedigitals.co.za
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;