import React from "react";
import project_01 from "../../../assets/img/project-07.jpg";
import project_02 from "../../../assets/img/project-08.jpg";
import project_03 from "../../../assets/img/project-09.jpg";
import "animate.css";
const ProjectComponentThree = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4 animate__animated animate__jackInTheBox">
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={project_01} className="img-fluid" alt="project 1" />
            </div>
          </div>
        </div>
        <div className="col-lg-4 animate__animated animate__jackInTheBox">
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={project_02} className="img-fluid" alt="project 1" />
            </div>
          </div>
        </div>
        <div className="col-lg-4 animate__animated animate__jackInTheBox">
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={project_03} className="img-fluid" alt="project 1" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectComponentThree;
