import React from "react";
import { useState } from "react";
import team from "../../../assets/img/team-01.jpg";
import team2 from "../../../assets/img/team-02.jpg";
import team3 from "../../../assets/img/team-03.jpg";
import team4 from "../../../assets/img/team-04.jpg";
import "animate.css";
const TeamComponent = () => {
  const [showProduct, setShowProduct] = useState(false);
  const [showProduct2, setShowProduct2] = useState(false);
  const [showProduct3, setShowProduct3] = useState(false);
  const [showProduct4, setShowProduct4] = useState(false);
  function MouseOver(e) {
    setShowProduct(true);
  }
  function MouseLeave(e) {
    setShowProduct(false);
  }
  function MouseOver2(e) {
    setShowProduct2(true);
  }
  function MouseLeave2(e) {
    setShowProduct2(false);
  }
  function MouseOver3(e) {
    setShowProduct3(true);
  }
  function MouseLeave3(e) {
    setShowProduct3(false);
  }
  function MouseOver4(e) {
    setShowProduct4(true);
  }
  function MouseLeave4(e) {
    setShowProduct4(false);
  }
  return (
    <>
      <div className="row justify-content-center">
        <div
          className="col-lg-3"
          onMouseEnter={MouseOver}
          onMouseLeave={MouseLeave}
        >
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={team2} className="img-fluid" alt="project 1" />
              {showProduct && (
                <div className="block-overlay animate__animated animate__backInLeft">
                  <h3>Derrick </h3>
                  <p>Creative Director</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-lg-3"
          onMouseEnter={MouseOver2}
          onMouseLeave={MouseLeave2}
        >
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={team} className="img-fluid" alt="project 1" />
              {showProduct2 && (
                <div className="block-overlay animate__animated animate__backInLeft">
                  <h3>Chris</h3>
                  <p>Full Stack Developer</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-lg-3"
          onMouseEnter={MouseOver3}
          onMouseLeave={MouseLeave3}
        >
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={team3} className="img-fluid" alt="project 1" />
              {showProduct3 && (
                <div className="block-overlay animate__animated animate__backInLeft">
                  <h3>Tshepo</h3>
                  <p>Technical Team Lead </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-lg-3"
          onMouseEnter={MouseOver4}
          onMouseLeave={MouseLeave4}
        >
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={team4} className="img-fluid" alt="project 1" />
              {showProduct4 && (
                <div className="block-overlay animate__animated animate__backInLeft">
                  <h3>Sabelo</h3>
                  <p>Software Tester</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamComponent;
