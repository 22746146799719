import React from "react";
import { Link } from "react-router-dom";

const TestimonialsComponents = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 block-overlay-testimonials">
          <div className="row justify-content-md-center">
            <div className="col-lg-10 block-margin-top">
              <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active animate__animated animate__backInRight">
                    <p>
                      The team at Creative Digitals is great to work with. Has been since my very first meeting with them
                      (they were the only agency that came to meet me in person). I’m very happy with the finished product
                      of our website and would highly recommend them to others.
                    </p>
                    <br />
                    <Link to="/about">
                      <p>
                        <em style={{
                          color: "#ffffff",
                          textDecorationStyle: "None",
                        }}>
                          NTGR Engineering
                        </em>
                      </p>
                    </Link>
                  </div>
                  <div className="carousel-item animate__animated animate__backInRight">
                    <p>
                      I worked with the creative team at Agency for a few months and was impressed by the thought that went
                      into the project and site being created. They did a great job of listening to my needs and creating
                      design that worked well for me and my business.
                    </p>
                    <br />
                    <Link to="/about">
                      <p>
                        <em style={{
                          color: "#ffffff",
                          textDecorationStyle: "None",
                        }}>
                          Mannschafteng
                        </em>
                      </p>
                    </Link>
                  </div>
                  <div className="carousel-item animate__animated animate__backInRight">
                    <p>
                      I have worked with Creative digitals team and they are so amazing. They manage all my online presence,
                      websites, shops and so on. They are very easy to work with and really helpful whenever I need
                      something done. Thank you for everything you do for me.
                    </p>
                    <br />
                    <Link to="/about">
                      <p>
                        <em style={{
                          color: "#ffffff",
                          textDecorationStyle: "None",
                        }}>
                          LL Concerpt
                        </em>
                      </p>
                    </Link>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TestimonialsComponents;