import React, { useState } from "react";
import ProjectComponentsOne from "./components/projectComponents/ProjectOne";
import ProjectComponentsTwo from "./components/projectComponents/ProjectTwo";
import ProjectComponentsThree from "./components/projectComponents/ProjectThree";
import ProjectComponentsFour from "./components/projectComponents/ProjectFour";
const Projects = () => {
  const [projectOne, setProjectOne] = useState(true);
  const [projectTwo, setProjectTwo] = useState(false);
  const [projectThree, setProjectThree] = useState(false);
  const [projectFour, setProjectFour] = useState(false);
  function MouseEnter(e) {
    setProjectTwo(false);
    setProjectThree(false);
    setProjectFour(false);
    setProjectOne(true);
  }
  function MouseEnter2(e) {
    setProjectOne(false);
    setProjectThree(false);
    setProjectFour(false);
    setProjectTwo(true);
  }
  function MouseEnter3(e) {
    setProjectOne(false);
    setProjectTwo(false);
    setProjectFour(false);
    setProjectThree(true);
  }
  function MouseEnter4(e) {
    setProjectOne(false);
    setProjectTwo(false);
    setProjectThree(false);
    setProjectFour(true);
  }
  return (
    <>
      <div className="row justify-content-md-center">
        <div className="col-lg-12">
          <div className="row justify-content-md-center">
            <div className="col-lg-8">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <span className="nav-link active block-span" onClick={MouseEnter}>
                    Graphic Design
                  </span>
                </li>
                <li className="nav-item">
                  <span className="nav-link block-span" onClick={MouseEnter2}>
                    Web Design/Web Development
                  </span>
                </li>
                <li className="nav-item">
                  <span className="nav-link block-span" onClick={MouseEnter3}>
                    Mobile APPS
                  </span>
                </li>
                <li className="nav-item">
                  <span className="nav-link block-span" onClick={MouseEnter4}>
                    E-Commerce
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {projectOne && (
            <div className="col-lg-12">
              <ProjectComponentsOne />
            </div>
          )}
        </div>
        <div className="row">
          {projectTwo && (
            <div className="col-lg-12">
              <ProjectComponentsTwo />
            </div>
          )}
        </div>
        <div className="row">
          {projectThree && (
            <div className="col-lg-12">
              <ProjectComponentsThree />
            </div>
          )}
        </div>
        <div className="row">
          {projectFour && (
            <div className="col-lg-12">
              <ProjectComponentsFour />
            </div>
          )}
        </div>
      </div>

    </>
  );
};
export default Projects;
