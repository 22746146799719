import React from "react";
import { Link } from "react-router-dom";

const AboutComponent = () => {
return (
<>
  <p>
    We’re a full service digital agency with a strong track record of delivering measurable results. We make it easy for
    businesses to grow through marketing automation, design and development. Our team is made up of experts in each
    aspect who work hand-in-hand with our clients.

  </p>
  <br />
  <Link to="/about" className="btn btn-primary block-primary">
  Read more about us
  </Link>
</>
);
};
export default AboutComponent;