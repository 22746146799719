import React from "react";
import { useState } from "react";
import project_01 from "../../../assets/img/project-01.jpg";
import project_02 from "../../../assets/img/project-02.jpg";
import project_03 from "../../../assets/img/project-03.jpg";
const ProjectsComponent = () => {
const [showProduct, setShowProduct] = useState(false);
const [showProduct2, setShowProduct2] = useState(false);
const [showProduct3, setShowProduct3] = useState(false);
function MouseOver(e) {
setShowProduct(true);
}
function MouseLeave(e) {
setShowProduct(false);
}
function MouseOver2(e) {
setShowProduct2(true);
}
function MouseLeave2(e) {
setShowProduct2(false);
}
function MouseOver3(e) {
setShowProduct3(true);
}
function MouseLeave3(e) {
setShowProduct3(false);
}

return (
<>
  <div className="row justify-content-center">
    <div className="col-lg-4" onMouseEnter={MouseOver} onMouseLeave={MouseLeave}>
      <div className="row">
        <div className="col-lg-12 block-relative">
          <img src={project_01} className="img-fluid" alt="project 1" />
          {showProduct && (
          <div className="block-overlay">
            <h3>Web Design</h3>
            <p>
              We create websites that work hard making your business look great.
            </p>
          </div>
          )}
        </div>
      </div>
    </div>
    <div className="col-lg-4" onMouseEnter={MouseOver2} onMouseLeave={MouseLeave2}>
      <div className="row">
        <div className="col-lg-12 block-relative">
          <img src={project_02} className="img-fluid" alt="project 1" />
          {showProduct2 && (
          <div className="block-overlay">
            <h3>Graphic Design</h3>
            <p>
              Whether you need an infographic, logo, or a complete branding package, we will work hand in hand with you
              to meet your needs.
            </p>
          </div>
          )}
        </div>
      </div>
    </div>
    <div className="col-lg-4" onMouseEnter={MouseOver3} onMouseLeave={MouseLeave3}>
      <div className="row">
        <div className="col-lg-12 block-relative">
          <img src={project_03} className="img-fluid" alt="project 1" />
          {showProduct3 && (
          <div className="block-overlay">
            <h3>Mobile App</h3>
            <p>
              We are a mobile app development company that provides a range of services including app design,
              development, marketing and optimization.
            </p>
          </div>
          )}
        </div>
      </div>
    </div>
  </div>

</>
);
};

export default ProjectsComponent;