import React from "react";
import { Link } from "react-router-dom";
const Nav = () => {
return (
<>
  <nav className="navbar navbar-expand-lg">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
      <ul className="navbar-nav ">
        <li className="nav-item">
          <Link to="/" className="nav-link" aria-current="page">
          Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link">
          About Us
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/services" className="nav-link">
          Services
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-link">
          Get in touch
          </Link>
        </li>
      </ul>
    </div>
  </nav>
</>
);
};
export default Nav;