import React from "react";
import Slider from "../Slider/slider";
import AboutComponent from "./components/HomeComponents/aboutComponent";
import ProjectsComponent from "./components/HomeComponents/projectsComponents";
import TestimonialsComponents from "./components/HomeComponents/testimonialsComponent";

const Home = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 block-remove-padding">
          <Slider />
        </div>
        <div className="col-lg-12 block-gray">
          <AboutComponent />
        </div>
        <div className="col-lg-12">
          <ProjectsComponent />
        </div>
        <div className="col-lg-12 block-background">
          <TestimonialsComponents />
        </div>
      </div>
    </>
  );
};

export default Home;
