import React from "react";

import project_10 from "../../../assets/img/project-10.jpg";
import project_11 from "../../../assets/img/project-11.jpg";
import project_12 from "../../../assets/img/project-12.jpg";
import "animate.css";
const ProjectComponentsFour = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4 animate__animated animate__jackInTheBox">
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={project_10} className="img-fluid" alt="project 1" />
            </div>
          </div>
        </div>
        <div className="col-lg-4 animate__animated animate__jackInTheBox">
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={project_11} className="img-fluid" alt="project 1" />
            </div>
          </div>
        </div>
        <div className="col-lg-4 animate__animated animate__jackInTheBox">
          <div className="row">
            <div className="col-lg-11 block-relative">
              <img src={project_12} className="img-fluid" alt="project 1" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectComponentsFour;
