import React from "react";

const Page404 =() => {
    return(
        <>
           <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                <h1>Error 404 Page not found....</h1>
                </div>
              </div>
           </div>
        </>
    )
}
export  default Page404;