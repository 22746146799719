import React from "react";
import Banner from "../assets/img/about.jpg";
import TeamComponent from "./components/AboutComponents/teamComponent";

const About = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 block-remove-pad">
          <img src={Banner} width="100%" alt="About us " />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 block-about-us">
            <h2>What we do</h2>
            <p>
              <em>We <strong>think . plan . create</strong>. Our experts work with clients to tackle the toughest business
                challenges and design outcomes that move your brand forward.</em>
            </p>
            <p>
              A creative digital agency is a brand new digital agency that is offering various services to start-ups and brand
              owners. We are known as a reliable source for creating amazing websites, apps and eCommerce solutions.
            </p>
            <p>
              We’re a full service digital agency with a strong track record of delivering measurable results. We make it easy
              for businesses to grow through marketing automation, design and development. Our team is made up of experts in
              each aspect who work hand-in-hand with our clients.
            </p>
            <p>
              A digital agency that helps brands grow by creating engaging interactive experiences for their customers. We're
              a creative and tech agency all in one, with offices across the globe.

            </p>
          </div>
          <div className="col-lg-6 block-about-us">
            <h2>Mission</h2>
            <p>
              We are a creative digital agency who are passionate about creating great experiences for our clients and digital
              audiences alike. We believe that the way forward is to combine design, digital and strategy seamlessly to drive
              business results.
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-12 block-team">
            <h2>OUr Team</h2>
            <TeamComponent />
          </div>
        </div>

      </div>
    </>
  );
};

export default About;